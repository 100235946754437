import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import './simpleDialog.scss'

const SimpleDialog = (props) => {
    const { open, status, title, description, handleClose } = props

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            PaperProps={{
                style: {
                    backgroundColor: '#D9D7A3'
                }
            }} >
            <DialogTitle><div className={status ? 'title': 'error'}>{title}</div></DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <div className={'description'}>{description}</div>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleClose}
                    color="primary"
                    autoFocus>
                    Done
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default SimpleDialog
