import React from 'react';
import { Provider } from 'react-redux';
import Routes from './Routes/routes';
import history from './History/history'
import ErrorBoundary from './Pages/Error Boundary/errorboundary';
import { pages } from './Constants/constants';
import './App.css';

class App extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      pages: pages
    }
  }

  componentDidMount = () => {
    if (!this.state.pages.includes(this.props.history.location.pathname)) {
      window.location = pages[0]
    }
  }

  render() {
    let { store, history } = this.props

    return (
      <ErrorBoundary>
        <Provider store={store} >
          <Routes history={history} />
        </Provider>
      </ErrorBoundary>
    );
  }
}

export default App
