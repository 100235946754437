import React from 'react'
import { Grid } from '@material-ui/core'
import './footer.scss'

let links = [
    { title: 'Instagram', link: 'https://www.instagram.com/iamnatasha.r_/'},
    { title: 'Twitter', link: 'https://twitter.com/Sexijel'},
    { title: 'LinkedIn', link: 'http://linkedin.com/in/natasha-reid-66b246131'}
]

let Footer = (props) => (
    <div style={{ marginTop: 50 }}>
        <Grid container className={props.isContactsPage ? "fixedFooter": "footer"} direction={"row"} justify={'space-around'}>
            {links.map((link, _) => (
                <Grid item xs={3}>
                    <div className={"footerItems"}><a href={link.link} target={"_blank"} rel="noopener noreferrer">{link.title.toUpperCase()}</a></div>
                </Grid>
            ))}
        </Grid>
    </div>
)

export default Footer
