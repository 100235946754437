import React from 'react'
import { headerLinks } from '../../Constants/constants'
import './sidemenu.scss'

let getClassName = (selectedIndex, currentIndex) => {
    return selectedIndex === currentIndex ? "sideMenuNames sideMenuProjects selectedSideMenu": "sideMenuNames sideMenuProjects"
}

let SideMenu = (props) => {
    return (
        <div className={"headerSideBar"} style={{ backgroundColor: '#65793C' }}>
            {headerLinks.map((link, index) => (
                <div>
                    <div className={getClassName(props.selectedIndex, index)}><a href={link.link}>{link.title.toUpperCase()}</a></div>
                    <div className={"divider"}></div>
                </div>
            ))}
        </div>
    )
}

export default SideMenu
