import React from 'react'
import { Grid } from '@material-ui/core'
import Header from '../../Components/Header/header';
import { getSideBarOpenStatus } from '../../Constants/sidemenuconstants'
import Footer from '../../Components/Footer/footer'
import { PRODUCTION_ENVIRONMENT, aboutMePage } from '../../Constants/constants'
import { updateFirebasePageData } from '../../Networking/httprequests.js'
import './aboutme.scss'
import firstImage from '../../Assets/Images/Natasha Reid/Images/first.jpg'
import secondImage from '../../Assets/Images/Natasha Reid/Images/second.jpg'
import thirdImage from '../../Assets/Images/Natasha Reid/Images/third.jpg'

class AboutUs extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            isSideBarOpen: false,
            images: [firstImage, secondImage, thirdImage],
            aboutOptions: [
                { title: 'About Me', description: 'Natasha Reid took her first breathe in the cool hills of Mandeville, Manchester, Jamaica. She was raised in St. Catherine where her mother who also fathered her did an exceptional job by teaching her priceless gems that others only stumble upon by the time they get to adulthood.' },
                { title: 'Born To Be An Entrepreneur', description: 'Being brought up in an entrepreneurial home, where her mom and step-father ran their own businesses, She learnt from as early as kindergarten that she was destined to become a tycoon. At about 9 years old She was already hustling and selling candies and snacks to her schoolmates. She continued that small business while in high school when an opportunity came about where She needed to operate a business for her Principle of Business, assignment. At Age 10 she received an award for outstanding performance in Social Studies, she was awarded with a gift card for Jamaican National Building Society. This award enabled her to acquire a Bank Account. By the time She got to age 13, she was the junior/apprentice accountant at her step-dad’s bakery. Natasha would basically record and tally all the inventory and have all the books done for the actual accountant to retrieve and do their part.' },
                { title: 'Education Is Key', description: 'Even though Natasha was so entrepreneurial minded, She also understood the importance of having an education. She is a proud graduated of the University of The West Indies, where she perused a BSc. in Economics. While in university at age 19, She started her first money magnet business, a construction consultant company. To date, she owns and operate multiple businesses both in Jamaica and in the USA.' },
                {
                    title: 'Social Involvement', options: [
                        { title: 'School Challenge Quiz', description: 'Natasha was a proud participant in the National School Challenge Quiz Competition while attending high school in Jamaica. She was recruited in 3rd form to major in the Languages ( Spanish & French), Mathematics & Literature.' },
                        { title: 'United Nations International School (UNIS), New York', description: 'Having a good education is very essential, however to become a well rounded individual in our society one has to broaden their horizons and that much Ms. Reid did. She represented her Alma Mater, St Hugh’s High at the UNIS conference twice in New York at the United Nations Headquarters at their annual event. Each year, students from all over the world would meet at this event and speak on world affairs. This sparked her interest in the UN mandate for humanity.' },
                        { title: 'Renovation of Water Commission Basic school', description: 'While attending the UWI, Mona, Natasha volunteered to work on a project which geared at renovating and donating books to the students library and sick bay at the Water Commission Basic school in Papine, Kingston.  One of Natasha’s life dream is to create a charity organization which will help students and children in need across different geographic space.' },
                        { title: 'Jam-fit Cheer Leading Competition', description: 'Sports was never Natasha’s strong hold however, one of her God given talents is dancing. As a result she managed to cheer a lot during 6th form and represented the St Hugh’s High School in the National Jam-fit competition and placed 4th. Even though her team didn’t win, she was elated to have made it to the top 5.   Cheerleading is the only sport she wouldn’t gladly participate in again.' }
                    ]
                }
            ]
        }
    }

    componentDidMount = () => {
        document.title += ' - About Me'

        if (process.env.NODE_ENV === PRODUCTION_ENVIRONMENT) {
            updateFirebasePageData(aboutMePage)
        }

        window.addEventListener('resize', this.windowWasResized)
    }

    componentWillUnmount = () => {
        window.removeEventListener('resize', this.windowWasResized)
    }

    windowWasResized = () => {
        this.setState({ isSideBarOpen: getSideBarOpenStatus() })
    }

    showSideBar = () => {
        this.setState({ isSideBarOpen: !this.state.isSideBarOpen })
    }

    render() {
        const cssColors = {
            evenColor: '#BFBC6B',
            oddColor: '#D9D7A3'
        }

        let { isSideBarOpen, images, aboutOptions } = this.state

        return (
            <div>
                <Header
                    isSideBarOpen={isSideBarOpen}
                    showSideBar={this.showSideBar}
                    width={window.innerWidth}
                    selectedIndex={1} />

                <Grid container direction={'row'} justify={'space-around'} spacing={20} style={{ marginTop: 50, padding: 10 }}>
                    {images.map((image, index) => (
                        <Grid item xs={12} sm={3}>
                            <div><img className={index === 0 ? 'firstImage' : index === images.length - 1 ? 'lastImage' : 'images'} src={image} alt={'image: ' + (index + 1)} width={'100%'} height={'100%'} /></div>
                        </Grid>
                    ))}
                </Grid>

                <div className={'aboutUsBackground'} style={{ marginBottom: -55 }}>
                    {aboutOptions.map((value, index) => (
                        <div style={index === 0 ? { marginTop: -10 } : { marginTop: 10 }}>
                            <Grid className={'optionBackground'} container direction={'row'} justify={'space-around'} style={index % 2 === 0 ? { backgroundColor: cssColors.evenColor } : { backgroundColor: cssColors.oddColor }}>
                                <Grid item xs={12} sm={6} className={'largeTitleBackground'}>
                                    <div className={'largeTitle'}>{value.title.toUpperCase()}</div>
                                </Grid>
                                {value.options ?
                                    value.options.map((data, subIndex) => (
                                        <Grid container direction={'row'} justify={'space-around'} alignItems={'baseline'}>
                                            <Grid item xs={12} sm={4}>
                                                <div className={'involvementDescription'}>{subIndex + 1}. {data.title}</div>
                                            </Grid>
                                            <Grid item xs={12} sm={6} style={subIndex === value.options.length - 1 ? { marginTop: 5, marginLeft: 30, marginBottom: 15 } : { marginTop: 5, marginLeft: 30, marginBottom: 15 }}>
                                                <div className={'involvementDescription'}>{data.description}</div>
                                            </Grid>
                                        </Grid>
                                    )) :
                                    <Grid item xs={12} sm={6}>
                                        <div className={'description'}>{value.description}</div>
                                    </Grid>}
                            </Grid>
                        </div>
                    ))}
                </div>

                <Footer />
            </div>
        )
    }
}

export default AboutUs
