import firebase from './firebase'
import { viewData } from '../Constants/constants';

export let updateFirebasePageData = (page) => {
    let date = new Date();

    let dateDictionary = {
        date: (date.getDate() < 10 ? "0" : "") + date.getDate() + "/" + ((date.getMonth() + 1) < 10 ? "0" : "") + (date.getMonth() + 1) + "/" + date.getFullYear(),
        time: (date.getHours() < 10? "0": "") +date.getHours() + ":" + (date.getMinutes() < 10 ? "0": "") + date.getMinutes() + ":" + date.getMilliseconds(),
        string: date.toString(),
    }

    var navigatorDictionary = {
        appCodeName: navigator.appCodeName,
        appName: navigator.appName,
        cookieEnabled: navigator.cookieEnabled,
        location: navigator.geolocation,
        vendor: navigator.vendor,
        webDriver: navigator.webdriver === undefined ? "": navigator.webdriver,
        userAgent: navigator.userAgent,
        product: navigator.product,
        platform: navigator.platform,
        javaEnabled: navigator.javaEnabled()
    };

    if ((navigator.languages[0] !== null) && (navigator.languages[0] !== undefined)) {
        navigatorDictionary["language"] = navigator.languages[0]
    }

    let screenDictionary = {
        height: window.innerHeight,
        width: window.innerWidth,
        orientation: Math.abs(window.orientation) - 90 === 0 ? "landscape" : "portrait"
    }
    
    var dictionary = {
        date: dateDictionary,
        navigator: navigatorDictionary,
        screen: screenDictionary
    };
    
    firebase.app.database().ref().child(viewData + page).child("" + (date.getDate() < 10 ? "0" : "") + date.getDate() + ((date.getMonth() + 1) < 10 ? "0" : "") + (date.getMonth() + 1) + date.getFullYear()).push().set(dictionary, function (error) {
    });
}