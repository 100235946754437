import React from 'react'
import { Grid } from '@material-ui/core'
import menuIcon from '../../Assets/Images/Menu Icon/menuicon.png'
import closeIcon from '../../Assets/Images/Close Icon/closeicon.png'
import SideMenu from '../Side Menu/sidemenu';
import natashaReid from '../../Assets/Images/Natasha Reid/icon.png';
import { headerLinks } from '../../Constants/constants.js'
import './header.scss'

let leftMargin = (width) => {
    return {
        marginLeft:
            width > 1400 ? '60%'
                : width > 1300 ? '55%'
                    : width > 1200 ? '50%'
                        : width > 800 ? '40%'
                            : width > 700 ? '35%'
                                : width < 700 ? '25%'
                                    : '25%'
    }
}

let getClassName = (selectedIndex, currentIndex) => {
    return selectedIndex === currentIndex ? "headerNames headerItems selectedHeaderItem" : "headerNames headerItems"
}

let Header = (props) => (
    <Grid container className={"headerBackground"} direction="row" justify="space-between">
        <Grid item xs={2} sm={1}>
            <div className={"headerNames headerName"}>
                <a href={"/"}><img src={natashaReid} alt={""} className={"natashaReidImage"} /></a>
                <a href="/" className={"natashaReidName"}>NATASHA REID</a>
            </div>
        </Grid>
        <Grid container direction="row">
            <Grid item xs={2} className={"headerMenuIcon"}>
                <div onClick={() => { props.showSideBar() }}>
                    <img className={props.isSideBarOpen ? "closeMenuIcon" : "headerMenuIcon"} src={props.isSideBarOpen ? closeIcon : menuIcon} alt={"Img"} />
                </div>
            </Grid>
            {props.isSideBarOpen === true ?
                <SideMenu
                    selectedIndex={props.selectedIndex} /> :
                <Grid container className={'allRightItems'} direction={'row'} justify={'space-evenly'} style={leftMargin(props.width)} spacing={5}>
                    {headerLinks.map((link, index) => (
                        <Grid item xs={2} className={"headerRightItems"} style={index === 0 ? {marginLeft: 50, marginRight: -30 }: index === headerLinks.length-1 ? { marginRight: 20 }: null}>
                            <div className={getClassName(props.selectedIndex, index)}>
                                <a href={link.link}>{link.title.toUpperCase()}</a>
                            </div>
                        </Grid>
                    ))}
                </Grid>}
        </Grid>
    </Grid>
)

Header.propTypes = {
};

Header.defaultProps = {
    width: window.innerWidth
};

export default Header
