// MARK: - Environmental Constants
export let apiKey = process.env.REACT_APP_API_KEY
export let authDomain = process.env.REACT_APP_AUTH_DOMAIN
export let appId = process.env.REACT_APP_APP_ID
export let databaseUrl = process.env.REACT_APP_DATABASE_URL
export let projectId = process.env.REACT_APP_PROJECT_ID
export let storageBucket = process.env.REACT_APP_STORAGE_BUCKET
export let messagingSenderId = process.env.REACT_APP_MESSAGING_SENDER_ID

export let viewData = process.env.REACT_APP_RECORD_VIEW_DATA
export let homePage = process.env.REACT_APP_RECORD_HOME_PAGE
export let aboutMePage = process.env.REACT_APP_RECORD_ABOUT_ME_PAGE
export let contactsPage = process.env.REACT_APP_RECORD_CONTACTS_PAGE
export let galleryPage = process.env.REACT_APP_RECORD_GALLERY_PAGE

export let middleWareApiUrl = process.env.REACT_APP_MIDDLEWARE_API_URL
export let submitUrl = process.env.REACT_APP_SUBMIT_URL
export let submitAPIKey = process.env.REACT_APP_API_KEY

export let PRODUCTION_ENVIRONMENT = "production"

// MARK: - Different pages in the application
export let home = "/"
export let aboutMe = "/about-me"
export let contacts = '/contacts'
export let gallery = '/gallery'
export let pages = [home, aboutMe, gallery, contacts]

export let headerLinks = [
    { title: 'Home', link: home },
    { title: 'About Me', link: aboutMe },
    // { title: 'Gallery', link: gallery },
    { title: 'Contacts', link: contacts },
    // { title: 'Blog', link: '#' },
]
