import React from 'react'
import { Router, Route, Switch } from 'react-router-dom'
import Home from '../Pages/Home/home.js'
import AboutUs from '../Pages/AboutMe/aboutme.js'
import Contacts from '../Pages/Contacts/contacts.js'
import PhotoGallery from '../Pages/Gallery/gallery.js'
import { home, aboutMe, contacts, gallery } from '../Constants/constants';

export const Routes = (props) => (
    <Router history={props.history}>
        <Switch>
            <Route exact path={home} component={Home}></Route>
            <Route exact path={aboutMe} component={AboutUs}></Route>
            <Route exact path={contacts} component={Contacts}></Route>
            {/* <Route exact path={gallery} component={PhotoGallery}></Route> */}
        </Switch>
    </Router>
)

export default Routes
