import React from 'react'
import { Grid, TextField, Button } from '@material-ui/core'
import Header from '../../Components/Header/header';
import { getSideBarOpenStatus } from '../../Constants/sidemenuconstants'
import Footer from '../../Components/Footer/footer'
import { PRODUCTION_ENVIRONMENT, contactsPage } from '../../Constants/constants'
import { updateFirebasePageData } from '../../Networking/httprequests.js'
import { updateObject, checkValidity } from '../../Utility/utility.js'
import './contacts.scss'
import SimpleDialog from '../../Components/Dialog/SimpleDialog/simpleDialog.js'
import LoadingIndicator from '../../Components/LoadingIndicator/LoadingIndicator';
import HttpClient from '../../Networking/httpclient.js';

class Contacts extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            isSideBarOpen: false,
            heading: {
                title: "Let's Connect", description: 'Let’s connect, please feel free to write Natasha if you have a question or comment or even if you just want to say greetings.'
            },
            nameField: {
                id: "name",
                formElement: "nameField",
                label: "Name",
                className: null,
                errorMessage: null,
                validation: {
                    isNotEmpty: true
                },
                isValid: false,
                value: ""
            },
            telephoneField: {
                id: "telephone",
                formElement: "telephoneField",
                label: "Telephone Number",
                className: null,
                errorMessage: null,
                validation: {
                    isNotEmpty: true,
                    isPhoneNumber: true
                },
                isValid: false,
                value: ""
            },
            emailField: {
                id: "email",
                formElement: "emailField",
                label: "Email Address",
                className: null,
                errorMessage: null,
                validation: {
                    isNotEmpty: true,
                    isEmail: true
                },
                isValid: false,
                value: ""
            },
            companyField: {
                id: "company",
                formElement: "companyField",
                label: "Company",
                className: null,
                errorMessage: null,
                validation: {
                    isNotEmpty: true
                },
                isValid: false,
                value: ""
            },
            messageField: {
                id: "message",
                formElement: "messageField",
                label: "Enter message",
                className: null,
                errorMessage: null,
                validation: {
                    isNotEmpty: true
                },
                isValid: false,
                value: ""
            },
            isSubmitDisabled: true,
            showLoadingIndicator: false,
            dialog: {
                open: false,
                status: false,
                title: "",
                description: ""
            }
        }
    }

    componentDidMount = () => {
        document.title += ' - Contacts'

        if (process.env.NODE_ENV === PRODUCTION_ENVIRONMENT) {
            updateFirebasePageData(contactsPage)
        }

        window.addEventListener('resize', this.windowWasResized)
    }

    componentWillUnmount = () => {
        window.removeEventListener('resize', this.windowWasResized)
    }

    windowWasResized = () => {
        this.setState({ isSideBarOpen: getSideBarOpenStatus() })
    }

    showSideBar = () => {
        this.setState({ isSideBarOpen: !this.state.isSideBarOpen })
    }

    handleChange = (formElement) => async (event) => {
        let validity = checkValidity(event.target.value, this.state[formElement].validation)

        let updatedControls = updateObject(this.state[formElement], {
            value: event.target.value,
            errorMessage: validity[1],
            isValid: validity[0],
        })

        this.setState({ [formElement]: updatedControls }, () => {
            let canSubmit = this.state.nameField.isValid && this.state.telephoneField.isValid && this.state.emailField.isValid && this.state.companyField.isValid && this.state.messageField.isValid

            this.setState({ isSubmitDisabled: !canSubmit })
        })
    }

    submitButtonWasPressed = (event) => {
        event.preventDefault()

        const { nameField, telephoneField, emailField, companyField, messageField } = this.state

        let body = {
            name: nameField.value,
            telephone: telephoneField.value,
            email: emailField.value,
            company: companyField.value,
            message: messageField.value
        }

        this.submitToBackend(body)
    }

    submitToBackend = (body) => {
        this.setState({ showLoadingIndicator: true, isSubmitDisabled: true })

        new HttpClient().submitContactForm(body).then((data) => {
            data.json().then((value) => {
                if (value.status === 'success') {
                    this.actionsAfterProcessingRequest({
                        status: true,
                        title: 'Submitted Successfully',
                        description: 'Your data was submitted successfully. You will be contacted shortly. Thank you.'
                    })
                }
                else {
                    this.actionsAfterProcessingRequest()
                }
            }).catch((_) => {
                this.actionsAfterProcessingRequest()
            })
        }).catch((_) => {
            this.actionsAfterProcessingRequest()
        })
    }

    actionsAfterProcessingRequest = (data) => {
        let { dialog } = this.state

        dialog.open = true
        dialog.status = data ? data.status : false
        dialog.title = data ? data.title : 'Error When Submitting'
        dialog.description = data ? data.description : 'An error occured when submitting the form. Please try again.'

        this.setState({ showLoadingIndicator: false, isSubmitDisabled: false, dialog }, () => {
            if (data)  {
                this.resetForm()
            }
        })
    }

    resetForm = () => {
        const { nameField, telephoneField, emailField, companyField, messageField } = this.state

        nameField.isValid = false
        nameField.value = ""
        nameField.errorMessage = null

        telephoneField.isValid = false
        telephoneField.value = ""
        telephoneField.errorMessage = null

        emailField.isValid = false
        emailField.value = ""
        emailField.errorMessage = null

        companyField.isValid = false
        companyField.value = ""
        companyField.errorMessage = null

        messageField.isValid = false
        messageField.value = ""
        messageField.errorMessage = null

        this.setState({ nameField, telephoneField, emailField, companyField, messageField, isSubmitDisabled: true })
    }

    closeModal = () => {
        let { dialog } = this.state

        dialog.open = false

        this.setState({ dialog })
    }

    render() {
        const cssColors = {
            evenColor: '#BFBC6B',
            oddColor: '#D9D7A3'
        }

        let { isSideBarOpen, heading, nameField, telephoneField, emailField, companyField, messageField, isSubmitDisabled, dialog, showLoadingIndicator } = this.state

        return (
            <div>
                <Header
                    isSideBarOpen={isSideBarOpen}
                    showSideBar={this.showSideBar}
                    width={window.innerWidth}
                    selectedIndex={2} />

                { showLoadingIndicator ? <LoadingIndicator /> : null}

                <div className={'contactsBackground'}>
                    <Grid className={'optionBackground'} container style={{ marginTop: 50, backgroundColor: cssColors.evenColor }} justify={'center'}>
                        <Grid item xs={12} className={'largeTitleBackground'}>
                            <div className={'largeTitle'} style={{ marginTop: 20 }}>{heading.title.toUpperCase()}</div>
                        </Grid>
                        <Grid item xs={12} className={'largeTitleBackground'}>
                            <div className={'description'}>{heading.description}</div>
                        </Grid>
                    </Grid>

                    <div className={'contactForm'}>
                        <Grid className={'optionBackground'} container style={{ marginTop: 60, backgroundColor: cssColors.oddColor }} justify={'center'}>
                            <Grid item xs={12} className={'largeTitleBackground'}>
                                <div className={'largeTitle'} style={{ fontSize: 25 }}>GET IN TOUCH</div>
                            </Grid>

                            <Grid container direction={'row'}>
                                <Grid item xs={12} sm={6} className={'largeTitleBackground'}>
                                    <TextField
                                        id={nameField.id}
                                        label={nameField.label}
                                        value={nameField.value}
                                        style={{ width: '95%' }}
                                        error={nameField.errorMessage}
                                        helperText={nameField.errorMessage}
                                        onChange={this.handleChange(nameField.formElement)} />
                                </Grid>
                                <Grid item xs={12} sm={6} className={'largeTitleBackground'}>
                                    <TextField
                                        id={telephoneField.id}
                                        label={telephoneField.label}
                                        value={telephoneField.value}
                                        style={{ width: '95%' }}
                                        error={telephoneField.errorMessage}
                                        helperText={telephoneField.errorMessage}
                                        onChange={this.handleChange(telephoneField.formElement)} />
                                </Grid>
                            </Grid>

                            <Grid container direction={'row'} style={{ marginTop: 5 }}>
                                <Grid item xs={12} sm={6} className={'largeTitleBackground'}>
                                    <TextField
                                        id={emailField.id}
                                        label={emailField.label}
                                        value={emailField.value}
                                        style={{ width: '95%' }}
                                        error={emailField.errorMessage}
                                        helperText={emailField.errorMessage}
                                        onChange={this.handleChange(emailField.formElement)} />
                                </Grid>

                                <Grid item xs={12} sm={6} className={'largeTitleBackground'}>
                                    <TextField
                                        id={companyField.id}
                                        label={companyField.label}
                                        value={companyField.value}
                                        style={{ width: '95%' }}
                                        error={companyField.errorMessage}
                                        helperText={companyField.errorMessage}
                                        onChange={this.handleChange(companyField.formElement)} />
                                </Grid>
                            </Grid>

                            <Grid container direction={'row'} style={{ marginTop: 5 }} justify={'center'}>
                                <Grid item xs={12} sm={5} className={'largeTitleBackground'}>
                                    <TextField
                                        id={messageField.id}
                                        label={messageField.label}
                                        value={messageField.value}
                                        style={{ width: '95%' }}
                                        error={messageField.errorMessage}
                                        helperText={messageField.errorMessage}
                                        onChange={this.handleChange(messageField.formElement)} />
                                </Grid>
                            </Grid>

                            <Grid container direction={'row'} justify={'center'} style={{ marginTop: 20 }}>
                                <Grid item xs={2} sm={2}>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        disabled={isSubmitDisabled}
                                        style={{ backgroundColor: "#FAFAFA" }}
                                        onClick={this.submitButtonWasPressed}>
                                        Submit
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                </div>

                <SimpleDialog
                    open={dialog.open}
                    status={dialog.status}
                    handleClose={this.closeModal}
                    title={dialog.title}
                    description={dialog.description} />

                <Footer
                    isContactsPage={true} />
            </div>
        )
    }
}

export default Contacts
