import React from 'react'
import { Grid } from '@material-ui/core'
import Header from '../../Components/Header/header';
import { getSideBarOpenStatus } from '../../Constants/sidemenuconstants'
import Footer from '../../Components/Footer/footer'
import { PRODUCTION_ENVIRONMENT, homePage } from '../../Constants/constants'
import { updateFirebasePageData } from '../../Networking/httprequests.js'
import './home.scss'

class Home extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            isSideBarOpen: false,
            homeOptions: [
                { title: 'Introduction', description: 'Natasha Reid, BSc. Economics is a serial entrepreneur. I started my first venture at age 19, in the contracting industry. I was NCC certified at Grade 4 in building maintenance & landscaping. While reading for my degree in economics and operating multiple businesses, hospitality choose me. I have a wealth of knowledge and experience in the field of Hospitality as well as Business Administration and Property Management. I learnt the formula for entrepreneurship while I was a child first hand. Find an idea that is feasible and just do it. You will be surprise to know, you will learn everything that you need to along the way.' },
                {
                    title: 'Business Ventures ', options: [
                        { title: 'Natasha Reid Construction Consultant Services', description: 'Natasha’s first venture business, registered with the National Contract Commission at grade 4 in Building Maintenance & Landscaping.' },
                        { title: 'Poultry Rearing Business', description: 'Natasha had a poultry rearing business where she grew 400 to 1000 chicken every six (6) weeks. She supplies poultry to schools and restaurants across Jamaica. This was a very lucrative business that provided an extra income for her while studying for her degree.' }
                    ]
                },
            ],
            majorProjects: [
                {
                    title: 'Major Projects',
                    options: [
                        { title: 'Repairs and Renovation to the Eltham High School, St. Catherine Jamaica.' },
                        { title: 'Renovation and addition to the Bonnett Primarty School.' },
                        { title: 'Renovations and Additions to the Cassava River Primary School. Built a stone wall.' },
                        { title: 'Developed a teacher’s homes at the Sandy Bank Primary School.' }
                    ]
                }
            ],
            otherSubHeadings: {
                titles: ['The Birth of the Verdant Hospitality Services', 'Start A Business Both In Jamaica & the USA'],
                subTitles: {
                    0: [{ title: 'Verdant Realtor LLC' }],
                    1: [
                        { title: 'Starting a business in Jamaica.' }]
                },
                options: {
                    0: [[
                        { description: 'February 2021 Natasha decided to take another step towards achieving her goal of owning a realtor company. This brought about the birth of the Verdant Realtor LLC. She is a certified real estate professional in the state of Florida. Having prior experience in property management, I believe it was time to make this big move into owning a real estate business. Verdant Realtor focuses on wholesale real estate investments. If you have money laying around, let me help you make your money work for you, let’s invest together and start creating you that passive income.', showStep: false }]
                    ],
                    1: [[
                        { description: 'Create a business name and research that name to ensure it is available. Register you business name at the registrar of companies office located on Grenada Way, Kingston, Jamaica.', usaDescription: null, showStep: true },
                        { description: 'Register with the NIS and get compliance letter.', usaDescription: null, showStep: true },
                        { description: 'Register with the NHT.', usaDescription: null, showStep: true },
                        { description: 'Acquire compliance and clearance letters from both NIS and NHT, then take these the inland revenue department where you will apply for a Tax Compliance Certificate (TCC).', usaDescription: null, showStep: true },
                        { description: 'Apply for a good and service service at the NCC/ Planning Institute of Jamaica.', usaDescription: null, showStep: true },
                        { description: 'Start sorting and trading.', usaDescription: null, showStep: true }]
                    ]
                },
            }
        }
    }

    componentDidMount = () => {
        document.title += ' - Home'

        if (process.env.NODE_ENV === PRODUCTION_ENVIRONMENT) {
            updateFirebasePageData(homePage)
        }

        window.addEventListener('resize', this.windowWasResized)
    }

    componentWillUnmount = () => {
        window.removeEventListener('resize', this.windowWasResized)
    }

    windowWasResized = () => {
        this.setState({ isSideBarOpen: getSideBarOpenStatus() })
    }

    showSideBar = () => {
        this.setState({ isSideBarOpen: !this.state.isSideBarOpen })
    }

    render() {
        const cssColors = {
            evenColor: '#BFBC6B',
            oddColor: '#D9D7A3'
        }

        let { isSideBarOpen, homeOptions, majorProjects, otherSubHeadings } = this.state

        return (
            <div>
                <Header
                    isSideBarOpen={isSideBarOpen}
                    showSideBar={this.showSideBar}
                    width={window.innerWidth}
                    selectedIndex={0} />

                

                <div className={'homeBackground'} style={{ marginBottom: -55 }}>
                    {homeOptions.map((value, index) => (
                        <Grid className={'optionBackground'} container direction={'row'} justify={'space-around'} style={index === 0 ? { marginTop: 50, backgroundColor: cssColors.evenColor } : { backgroundColor: cssColors.oddColor }}>
                            <Grid item xs={12} className={'largeTitleBackground'}>
                                <div className={'largeTitle'} style={{ marginTop: 20 }}>{value.title.toUpperCase()}</div>
                            </Grid>
                            {value.options ?
                                value.options.map((data, subIndex) => (
                                    <Grid container direction={'row'} justify={'space-evenly'} alignItems={'baseline'}>
                                        <Grid item xs={12} sm={4}>
                                            <div className={'description'}>{subIndex + 1}. {data.title}</div>
                                        </Grid>
                                        <Grid item xs={12} sm={6} style={subIndex === value.options.length - 1 ? { marginTop: 5, marginLeft: 30, marginBottom: 15 } : { marginTop: 5, marginLeft: 30, paddingBottom: 15 }}>
                                            <div className={'description'}>{data.description}</div>
                                        </Grid>
                                    </Grid>
                                )) :
                                <Grid item xs={12} sm={12}>
                                    <div className={'description'}>{value.description}</div>
                                </Grid>}
                        </Grid>
                    ))}

                    {majorProjects.map((data, _) => (
                        <Grid className={'optionBackground'} container direction={'row'} justify={'space-around'} style={{ backgroundColor: cssColors.evenColor }}>
                            <Grid item xs={12} className={'largeTitleBackground'}>
                                <div className={'largeTitle'}>{data.title.toUpperCase()}</div>
                            </Grid>
                            <Grid container direction={'row'} justify={'space-around'}>
                                {data.options.map((option, subIndex) => (
                                    <Grid item xs={12} sm={5} style={{ marginBottom: 15 }}>
                                        <div className={'description'}>{subIndex + 1}. {option.title}</div>
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    ))}

                    {window.innerWidth <= 600 ?
                        otherSubHeadings.titles.map((title, titleIndex) => (
                            <Grid className={'optionBackground'} container direction={'row'} justify={'space-around'} style={ titleIndex === 0 ? { backgroundColor: cssColors.oddColor}: { backgroundColor: cssColors.evenColor }}>
                                <Grid item xs={12} className={'largeTitleBackground'}>
                                    <div className={'largeTitle'}>{title.toUpperCase()}</div>
                                </Grid>
                                {otherSubHeadings.subTitles[titleIndex].map((subTitle, index) => (
                                    <div>
                                        <Grid item xs={12} sm={5}>
                                            <div className={'subTitle'}>{subTitle.title.toUpperCase()}</div>
                                        </Grid>

                                        {otherSubHeadings.options[titleIndex].map((data, subIndex) => (
                                            data.map((subData, otherIndex) => (
                                                index === 0 ?
                                                    <Grid container direction={'row'} justify={'space-around'}>
                                                        <Grid item xs={12} style={otherIndex === data.length - 1 ? { marginBottom: 10, marginTop: 15 } : { marginTop: 15 }}>
                                                            <div className={'description'}>{subData.showStep ? 'Step ' + (otherIndex + 1) + '. ' : null}{subData.description}</div>
                                                        </Grid>
                                                    </Grid> : subData.usaDescription ?
                                                        <Grid container direction={'row'} justify={'space-around'}>
                                                            <Grid item xs={12} style={otherIndex === data.length - 1 ? { marginBottom: 10, marginTop: 15 } : { marginTop: 15 }}>
                                                                <div className={'description'}>Step {otherIndex + 1}. {subData.usaDescription}</div>
                                                            </Grid>
                                                        </Grid> : null
                                            ))
                                        ))}
                                    </div>
                                ))}
                            </Grid>
                        )) :
                        otherSubHeadings.titles.map((title, titleIndex) => (
                            <Grid className={'optionBackground'} container direction={'row'} justify={'space-around'} style={ titleIndex === 0 ? { backgroundColor: cssColors.oddColor}: { backgroundColor: cssColors.evenColor }}>
                                <Grid item xs={12} className={'largeTitleBackground'}>
                                    <div className={'largeTitle'}>{title.toUpperCase()}</div>
                                </Grid>

                                {otherSubHeadings.subTitles[titleIndex].map((subTitle, index) => (
                                    <Grid item xs={12} sm={otherSubHeadings.subTitles[titleIndex].length === 1 ? 12 : 5}>
                                        <div className={'subTitle'}>{subTitle.title.toUpperCase()}</div>
                                    </Grid>
                                ))}

                                {otherSubHeadings.options[titleIndex].map((data, index) => (
                                    data.map((subData, subIndex) => (
                                        <Grid container direction={'row'} justify={'space-between'}>
                                            <Grid item xs={12} sm={otherSubHeadings.subTitles[1].length > 1 ? 5 : 12} style={{ marginTop: 15 }}>
                                                <div className={'description'}>{subData.showStep ? 'Step ' + (subIndex + 1) + '. ' : null}{subData.description}</div>
                                            </Grid>
                                            {otherSubHeadings.subTitles[1].length > 1 && subData.usaDescription ?
                                                <Grid item xs={12} sm={5} style={{ marginTop: 15 }}>
                                                    <div className={'description'}>Step {subIndex + 1}. {subData.usaDescription}</div>
                                                </Grid> : null}
                                        </Grid>
                                    ))
                                ))}
                            </Grid>
                        ))}
                </div>

                <Footer />
            </div>
        )
    }
}

export default Home
