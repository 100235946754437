import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress'
import './loadingindicator.scss'

let LoadingIndicator = () => {
    return (
        <div className={'circularProgress'}>
            <CircularProgress 
                color={'primary'}/>
        </div>
    );
}

export default LoadingIndicator
