import { middleWareApiUrl, submitAPIKey, submitUrl } from "../Constants/constants"

class HttpClient {
    async submitContactForm(body) {
        let options = {
            method: 'POST',
            headers: {
                'Content-type': 'application/json',
                'apikey': submitAPIKey
            },
            body: JSON.stringify(body)
        }

        return this._fetch(options)
    }

    async _fetch(options) {
        return this.timeout(30000, fetch(middleWareApiUrl + submitUrl, options)).then(data => {
            return data
        })
            .catch(error => {
                throw error
            })
    }

    timeout(ms, promise) {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                reject(new Error('Request timed out'))
            }, ms)
            promise.then(resolve, reject)
        })
    }
}

export default HttpClient
