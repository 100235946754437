import { createStore, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import browserHistory from '../History/history'
import logger from 'redux-logger';
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant';
import reducers from '../Reducers/reducers';
import { routerMiddleware } from 'react-router-redux';
import { PRODUCTION_ENVIRONMENT } from '../Constants/constants';

function configureReduxStoreForProduction (state) {
    let reactRouterMiddleware = routerMiddleware(browserHistory)

    let middlewares = [thunk, reactRouterMiddleware]

    return createStore(reducers, state, compose(applyMiddleware(...middlewares)))
}

function configureReduxStoreForDevelopment (state) {
    let reactRouterMiddleware = routerMiddleware(browserHistory)

    let middlewares = [reduxImmutableStateInvariant(), thunk, reactRouterMiddleware, logger]

    let composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

    let reduxStore = createStore(reducers, state, composeEnhancers(applyMiddleware(...middlewares)))

    return reduxStore
}

export default process.env.NODE_ENV === PRODUCTION_ENVIRONMENT ? configureReduxStoreForProduction({}) : configureReduxStoreForDevelopment({})
