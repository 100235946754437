export const updateObject = (oldObject, updatedProperties) => {
    return {
        ...oldObject,
        ...updatedProperties,
    };
};

export const checkValidity = (value, rules) => {
    let isValid = true;
    let errorMessage = null;

    if (!rules) {
        return [true, null]
    }

    if (rules.isNotEmpty) {
        isValid = value.trim() === "" ? false: true
        errorMessage = isValid ? null: "Please enter a valid value"
    }

    if (rules.isEmail) {
        const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
        isValid = pattern.test(value) && isValid;
        errorMessage = isValid === true ? null : 'Please enter a valid email address'
    }

    if (rules.isPhoneNumber) {
        const pattern = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
        isValid = pattern.test(value) && isValid;
        errorMessage = isValid === true ? null : 'Please enter a valid phone number'
    }

    return [isValid, errorMessage]
};

export default checkValidity;
