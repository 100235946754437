import React from 'react'
import Header from '../../Components/Header/header';
import { getSideBarOpenStatus } from '../../Constants/sidemenuconstants'
import { PRODUCTION_ENVIRONMENT, galleryPage } from '../../Constants/constants'
import Footer from '../../Components/Footer/footer'
import { updateFirebasePageData } from '../../Networking/httprequests.js'
import './gallery.scss'
import Gallery from 'react-photo-gallery'
import firstImage from '../../Assets/Images/Natasha Reid/Images/first.jpg'
import secondImage from '../../Assets/Images/Natasha Reid/Images/second.jpg'
import thirdImage from '../../Assets/Images/Natasha Reid/Images/third.jpg'

class PhotoGallery extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            isSideBarOpen: false,
            photos: [
                {
                    src: firstImage,
                    width: 1,
                    height: 1
                },
                {
                    src: secondImage,
                    width: 1,
                    height: 1
                },
                {
                    src: thirdImage,
                    width: 1,
                    height: 1
                }
            ]
        }
    }

    componentDidMount = () => {
        document.title += ' - Gallery'

        if (process.env.NODE_ENV === PRODUCTION_ENVIRONMENT) {
            updateFirebasePageData(galleryPage)
        }

        window.addEventListener('resize', this.windowWasResized)
    }

    componentWillUnmount = () => {
        window.removeEventListener('resize', this.windowWasResized)
    }

    windowWasResized = () => {
        this.setState({ isSideBarOpen: getSideBarOpenStatus() })
    }

    showSideBar = () => {
        this.setState({ isSideBarOpen: !this.state.isSideBarOpen })
    }

    render() {
        let { isSideBarOpen, photos } = this.state

        return (
            <div>
                <Header
                    isSideBarOpen={isSideBarOpen}
                    showSideBar={this.showSideBar}
                    width={window.innerWidth}
                    selectedIndex={2} />

                <div style={{ marginTop: 55 }}>
                    <Gallery
                        photos={photos} />
                </div>

                <Footer
                    isContactsPage={false} />
            </div>
        )
    }
}

export default PhotoGallery
