import React from 'react'
import Footer from '../../Components/Footer/footer';
import Header from '../../Components/Header/header';
import { Grid } from '@material-ui/core';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, info);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <div>
        <Header />

        <Grid container>
          <Grid item xs={12}>
            <div className={"nameAndDescription"}>
              <div className={"name"} style={{color: "#990000"}}>Something went wrong</div>
              <div className={"description"} style={{color: "#990000"}}>An error occured. Please try again</div>
              <div className={"description"} style={{color: "#990000", textAlign:"center", fontSize: 13, marginTop: 10}}>or &nbsp;<a href="mailto:tevonwallace@tevonwallace.com" style={{color: "#990000"}}>email</a>&nbsp;Tevon Wallace, Thanks</div>
            </div>
          </Grid>
        </Grid>
        
        <Footer />
      </div>;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;