import firebase from 'firebase'

import { apiKey, authDomain, appId, databaseUrl, projectId, messagingSenderId, storageBucket } from '../Constants/constants'

let config = {
    apiKey: apiKey,
    authDomain: authDomain,
    projectId: projectId,
    databaseURL: databaseUrl,
    appId: appId,
    storageBucket: storageBucket,
    messagingSenderId: messagingSenderId
}

export default !firebase.apps.length ? firebase.initializeApp(config).firestore() : firebase.app().firestore()
